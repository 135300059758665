import React from 'react'
import { Button } from 'reactstrap'
import uniq from 'lodash/uniq'
import { useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import { Select } from 'components/atoms'
import { useReferentiel } from 'api'

export const IatField: React.FC = () => {
  const { t } = useTranslation()
  const { ListeIat } = useReferentiel()
  const [iat, setIat] = React.useState<number | null>(null)
  const { setFieldValue, isSubmitting, setTouched } = useFormikContext()
  const [{ name, value }] = useField<number[]>('ListeIat')

  const options = React.useMemo(
    () => ListeIat.filter((iat) => !value.includes(iat.value)),
    [ListeIat, value],
  )

  React.useEffect(() => {
    if (iat === null && options.length > 0) {
      setIat(options[0].value)
    }
  }, [iat, options])

  if (options.length === 0) return null

  return (
    <div>
      <div css={{ display: 'flex', alignItems: 'flex-end', button: { marginLeft: '.6rem' } }}>
        <Select
          id={name}
          label={t('contract.billing.addIatLabel')}
          options={options}
          value={iat}
          onChange={setIat}
          all={false}
          disabled={isSubmitting}
          css={{ flex: 1 }}
        />

        <Button
          disabled={iat === null}
          onClick={() => {
            setFieldValue(name, uniq([...value, iat]))
            setIat(null)
            setTouched({ [name]: true }, false)
          }}
        >
          {t('global.add')}
        </Button>
      </div>
    </div>
  )
}
