import React from 'react'
import { Form, Spinner } from 'reactstrap'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/atoms'
import { useStyles } from 'hooks'
import { TextField } from 'components/form'
import { IatField } from 'components/sections/ContractFacturation/IatField'
import { IatTable } from 'components/sections/ContractFacturation/IatTable'

interface Props extends FormikProps<{ CompteFacturation: string; ListeIat: number[] }> {
  isLoading: boolean
}

export const FacturationForm: React.FC<Props> = ({ isLoading, ...props }) => {
  const { t } = useTranslation()

  const styles = useStyles((theme: any) => ({
    '>*': {
      marginTop: theme.spacing * 2,
    },
    '.mf-fields': {
      display: 'grid',
      gridGap: theme.spacing,
      gridTemplateColumns: '1fr 1fr',
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      button: { marginLeft: '0.6rem' },
    },
  }))

  return (
    <Form onSubmit={props.handleSubmit} css={styles}>
      <div className="mf-fields">
        <TextField name="CompteFacturation" label={t('contract.billing.accountLabel')} />
        <IatField />
      </div>

      <IatTable />

      <footer>
        <Button color="light" onClick={() => props.resetForm()}>
          {t('global.reset')}
        </Button>

        <Button
          type="submit"
          disabled={props.isSubmitting || Object.keys(props.touched).length === 0}
        >
          {isLoading && <Spinner size="sm" style={{ marginRight: '.6rem' }} />}
          {t('global.validate')}
        </Button>
      </footer>
    </Form>
  )
}
