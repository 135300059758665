import React, { FC } from 'react'
import { Button } from 'reactstrap'
import { motion } from 'framer-motion'

import { useTheme } from 'hooks'
import { Overlay } from 'components/atoms'

interface Props {
  isOpen: boolean
  close: () => void
  width?: number
  position?: 'left' | 'right'
}

export const SlidePanel: FC<any> = ({
  isOpen,
  close,
  width = 400,
  position = 'left',
  children,
  ...props
}) => {
  const theme = useTheme() as any

  const isRight = position === 'right'

  return (
    <Overlay isOpen={isOpen} onClick={close} {...props}>
      <motion.div
        css={{
          position: 'absolute',
          top: 0,
          backgroundColor: theme.gray200,
          height: '100%',
          overflowY: 'auto',
          width: width,
          padding: theme.spacing,
          left: isRight ? '100%' : 'unset',
          right: isRight ? 'unset' : '100%',
        }}
        animate={isOpen ? 'open' : 'close'}
        variants={{
          open: {
            translateX: width * (isRight ? -1 : 1),
            display: 'block',
          },
          close: {
            translateX: 0,
            transitionEnd: {
              display: 'none',
            },
          },
        }}
        transition={{
          duration: 0.4,
          ease: 'anticipate',
        }}
      >
        <Button close onClick={close} css={{ position: 'relative', zIndex: 1000 }} />
        {children}
      </motion.div>
    </Overlay>
  )
}
