import { useQuery, useMutation, queryCache, AnyQueryKey } from 'react-query'
import { superFetch } from 'commons'
import { ConsoBody,CdcBody } from 'types/api/ConsoBody'
import moment, { Moment } from 'moment'

export const useUpdateConsommation = () => {
  
    return useMutation<Partial<ConsoBody>, { cons: Partial<ConsoBody> }>(
    
      ({ cons}) =>
      superFetch({
            url: `consommations/compteurs/integration?numPdl=${cons.Nom}&dateDebut=${moment(cons.Debut, 'YYYY-MM-DD').toISOString()}&dateFin=${moment(cons.Fin, 'YYYY-MM-DD').toISOString()}&typConsommation=${cons.Type}&pas=${cons.Pas}&unite=${cons.Unite}&typeFichier=${cons.Csv}`,
            method: 'POST',
          body:cons?.file
        }),
      {
        useErrorBoundary: false,
        throwOnError: true,
        onSuccess: () => {
        
        },
      },
    )
  }

 export const useDownloadConsommation = () => {
    return useMutation<any, { cons: Partial<ConsoBody> }>(
      ({cons}) =>
      superFetch({
        url: `consommations/compteurs/${cons.Nom}/template?dateDebut=${moment(cons.Debut, 'YYYY-MM-DD').toISOString()}&dateFin=${moment(cons.Fin, 'YYYY-MM-DD').toISOString()}&typConsommation=${cons.Type}&pas=${cons.Pas}&unite=${cons.Unite}&typeFichier=${cons.Csv}`,
        parse: false,
        method: 'POST',
        responseType:'blob'
      }),
      {
        useErrorBoundary: false,
        throwOnError: true,
      },
    )
  }
  
  export const cdcDownload = () => {
  
    return useMutation< any, { payload: Partial<CdcBody> }>(
      ({ payload}) =>
      superFetch({
        url: 'sites/graph/cdc',
        method: 'POST',
        body: payload,
       
      }),
      {
        useErrorBoundary: false,
        throwOnError: true,
        onSuccess: () => {
        
        },
      },
    )
  }