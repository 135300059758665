import React from 'react'
import { Form, Button, Spinner } from 'reactstrap'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'

import { useTheme } from 'hooks'
import { TextField, DateField, SelectField } from 'components/form'
import { useReferentiel } from 'api'

interface Props extends FormikProps<Partial<Lot>> {
  isLoading: boolean
}

export const LotForm: React.FC<Props> = ({ isLoading, ...props }) => {
  const { t } = useTranslation()
  const theme = useTheme() as any

  const { ListeTypeLot, ListeHorosaisonnier, ListeFormulePrix } = useReferentiel()

  const isDisabled = isLoading || props.isSubmitting || Object.keys(props.touched).length === 0
  const hasError = Object.keys(props.errors).length > 0

  return (
    <Form
      onSubmit={props.handleSubmit}
      css={{
        section: {
          display: 'grid',
          gridGap: theme.spacing,
          gridTemplateColumns: 'repeat(2, 1fr)',
          paddingTop: theme.spacing,
          paddingBottom: theme.spacing * 2,
        },
        footer: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '>*': {
            display: 'flex',
            justifyContent: 'flex-end',
            button: { marginLeft: '0.6rem' },
          },
        },
      }}
    >
      <section>
        <TextField name="Nom" label={t('lot.infos.labels.nom')} />
        <SelectField name="Type" label={t('lot.infos.labels.type')} options={ListeTypeLot} />
        <DateField name="Debut" label={t('lot.infos.labels.debut')} />
        <DateField name="Fin" label={t('lot.infos.labels.fin')} />
        <SelectField
          name="Horosaisonnier"
          label={t('lot.infos.labels.hrs')}
          options={[{ value: 0, label: 'Aucun' }, ...ListeHorosaisonnier]}
        />
        <SelectField name="Tarif" label={t('lot.infos.labels.tarif')} options={ListeFormulePrix} />
      </section>

      <footer>
        <div />
        <div>
          <Button color="light" disabled={isDisabled} onClick={() => props.resetForm()}>
            {t('global.reset')}
          </Button>

          <Button type="submit" disabled={isDisabled || hasError}>
            {isLoading && <Spinner size="sm" style={{ marginRight: '.6rem' }} />}
            <span>{t('global.validate')}</span>
          </Button>
        </div>
      </footer>
    </Form>
  )
}
