import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createStructuredSelector } from 'reselect'

import { RootState } from 'store'

type Id = number | null
type Date = number | null

interface State {
  contractId: Id
  lotId: Id
  country: Id
  supplier: Id
  startYear: Date
  endYear: Date
}

const slice = createSlice({
  name: 'selection',
  initialState: {
    contractId: null,
    lotId: null,
    country: null,
    supplier: null,
    startYear: null,
    endYear: null,
  } as State,
  reducers: {
    setContractId: (state, action: PayloadAction<Id>) => ({
      ...state,
      contractId: action.payload,
      lotId: null,
    }),
    setLotId: (state, action: PayloadAction<Id>) => ({
      ...state,
      lotId: action.payload,
    }),
    setCountry: (state, action: PayloadAction<Id>) => ({
      ...state,
      country: action.payload,
    }),
    setSupplier: (state, action: PayloadAction<Id>) => ({
      ...state,
      supplier: action.payload,
    }),
    setStartYear: (state, action: PayloadAction<Date>) => ({
      ...state,
      startYear: action.payload,
    }),
    setEndYear: (state, action: PayloadAction<Date>) => ({
      ...state,
      endYear: action.payload,
    }),
  },
})

export const {
  reducer: selection,
  actions: { setContractId, setLotId, setCountry, setSupplier, setStartYear, setEndYear },
} = slice

// Selectors
export const getContractId = (state: RootState) => state.selection.contractId
export const getLotId = (state: RootState) => state.selection.lotId
export const getCountry = (state: RootState) => state.selection.country
export const getSupplier = (state: RootState) => state.selection.supplier
export const getStartYear = (state: RootState) => state.selection.startYear
export const getEndYear = (state: RootState) => state.selection.endYear

type Filters = Pick<State, 'country' | 'supplier' | 'startYear' | 'endYear'>
export const getFilters = createStructuredSelector<RootState, Filters>({
  country: getCountry,
  supplier: getSupplier,
  startYear: getStartYear,
  endYear: getEndYear,
})
