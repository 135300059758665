import React from 'react'
import { Button } from 'reactstrap'
import { useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import { Table } from 'components/atoms'
import { useReferentiel } from 'api'

export const IatTable: React.FC<any> = ({ ...props }) => {
  const { t } = useTranslation()
  const { ListeIat } = useReferentiel()

  const { setFieldValue, isSubmitting, setTouched } = useFormikContext()
  const [{ name, value }] = useField<number[]>('ListeIat')

  const data = React.useMemo(
    () => ListeIat.filter((iat) => value.includes(iat.value)),
    [ListeIat, value],
  )

  const columns = React.useMemo(
    () => [
      {
        accessor: 'value',
        width: 14,
        disableResizing: true,
        disableSortBy: true,
        align: 'center',
        Cell: ({ cell }: any) => (
          <Button
            close
            size="sm"
            style={{ padding: '.2rem' }}
            disabled={isSubmitting}
            onClick={() => {
              setFieldValue(
                name,
                value.filter((iat) => iat !== cell.value),
              )
              setTouched({ [name]: true }, false)
            }}
          />
        ),
      },
      {
        Header: 'IAT',
        accessor: 'label',
      },
    ],
    [isSubmitting, name, setFieldValue, setTouched, value],
  )

  if (data.length === 0) return <div>{t('contract.billing.empty')}</div>

  return <Table data={data} columns={columns} maxHeight={300} showHeader={false} {...props} />
}
