import React from 'react'
import { darken } from 'polished'

import { useTheme } from 'hooks'

export const Tab: React.FC<{
  selected?: boolean
  color?: 'light'
  disabled?: boolean
}> = ({ selected = false, color, disabled = false, children }) => {
  const theme = useTheme() as any

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: color === 'light' ? theme.blue : '#fff',
        fontWeight: 500,
        opacity: disabled ? 0.4 : selected ? 1 : 0.6,
        position: 'relative',
        pointerEvents: disabled ? 'none' : 'auto',
        '&:after': {
          content: '""',
          display: selected ? 'block' : 'none',
          height: 5,
          backgroundColor: color === 'light' ? theme.blue : darken(0.1, theme.gold),
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
        },
        '&:hover': {
          opacity: 1,
        },
      }}
    >
      {children}
    </div>
  )
}

export const Tabs: React.FC<{
  index: number
  color?: 'light'
}> = ({ index, color, children, ...props }) => {
  return (
    <nav
      {...props}
      css={{
        height: '100%',
        display: 'flex',
        '>*:not(:last-child)': { marginRight: '2rem' },
      }}
    >
      {React.Children.map(children, (child: any, i) =>
        React.cloneElement(child, { selected: index === i, color }),
      )}
    </nav>
  )
}
