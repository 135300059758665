import { useMutation, queryCache } from 'react-query'
import { useSelector } from 'react-redux'
import { superFetch } from 'commons'
import { getContractId } from 'store/selection'

export const useCreateVirtualSite = () => {
  const contractId = useSelector(getContractId)

  return useMutation<VirtualSitePEE,
    {
      contractId: number,
      virtualSiteRequest: {
        SiteName: string
      }[]
    }>(
    ({ contractId, virtualSiteRequest }) =>
      superFetch({
        url: `contrats/${contractId}/virtualSite`,
        method: 'POST',
        body: virtualSiteRequest,
      }),
    {
      useErrorBoundary: false,
      throwOnError: true,
      onSuccess: () => {
        //refetch organisations by force because it's cached
        queryCache.refetchQueries(['organisation', { contractId }], {force: true})
      },
    },
  )
}