import React, { FC } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { lighten, transparentize } from 'polished'
import { FaTrashAlt } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

import { useStyles, useBoolean } from 'hooks'
import { Dialog, Button } from 'components/atoms'
import { LotDeleteDialog } from './LotDeleteDialog'

export const LotLink: FC<{
  to: string
  done: () => void
  lotId: number
}> = ({ to, done, lotId, children }) => {
  const { t } = useTranslation()
  const match = useRouteMatch({ path: to })
  const history = useHistory()
  const [isModalOpen, toggleModal, closeModal] = useBoolean(false)

  const css = useStyles((theme: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: match ? 'rgba(0,117,164,.6196078431372549)' : 'transparent',
    padding: 2,
    '.link': {
      flex: 1,
      padding: '0.4rem 0.4rem',
      fontSize: '0.875rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '.action': {
      fontSize: '0.8rem',
      padding: '0.2rem 0.3rem',
      margin: '0 0.4rem',
      opacity: 0,
      transition: 'opacity .4s ease',
    },
    '&:hover': {
      backgroundColor: match
        ? lighten(0.2, theme.blue)
        : transparentize(0.5, lighten(0.2, theme.blue)),
      '.action': {
        opacity: 1,
      },
    },
  }))

  return (
    <>
      <div css={css}>
        <Button
          color="link"
          className="link"
          onClick={() => {
            done()
            history.push(to)
          }}
        >
          {children}
        </Button>

        <Button
          className="action"
          size="sm"
          color="danger"
          title={t('sidebar.deleteLot')}
          onClick={toggleModal}
        >
          <FaTrashAlt />
        </Button>
      </div>

      <Dialog isOpen={isModalOpen} close={closeModal} css={{ width: 500 }}>
        {isModalOpen && <LotDeleteDialog lotId={lotId} />}
      </Dialog>
    </>
  )
}
