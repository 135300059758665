import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { FacturationForm } from './FacturationForm'
import { useContract, useContractIat, useUpdateIat } from 'api'
import { addNotification } from 'notification'
import { getContractId } from 'store/selection'

export const ContractFacturation: React.FC = () => {
  const { t } = useTranslation()
  const contractId = useSelector(getContractId)
  const contract = useContract()
  const iats = useContractIat()
  const [mutate, { status }] = useUpdateIat()

  const values = {
    CompteFacturation: contract ? contract.Nom : '',
    ListeIat: iats ? iats.map((iat) => iat.Iat) : [],
  }

  const submit = React.useCallback(
    async (values, actions) => {
      if (contractId === null) return
      try {
        await mutate({ contractId, iats: values.ListeIat })
        actions.setSubmitting(false)
        addNotification({
          type: 'success',
          message: t('contract.billing.updateSuccess'),
        })
      } catch (error) {
        addNotification({
          type: 'danger',
          title: t('contract.billing.updateErrorTitle'),
          message: (error as any).toString(),
        })
      }
    },
    [contractId, mutate, t],
  )

  const schema = () =>
    Yup.object().shape({
      CompteFacturation: Yup.string().required(t('global.requiredField')),
      ListeIat: Yup.array().of(Yup.number()),
    })

  return (
    <Formik enableReinitialize initialValues={values} validationSchema={schema} onSubmit={submit}>
      {(props) => <FacturationForm isLoading={status === 'loading'} {...props} />}
    </Formik>
  )
}
