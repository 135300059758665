import React, { FC, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import get from 'lodash/get'

import { userManager, SilentRedirectPage, Callback, AccessDenied, Logout } from 'commons'
import { AppRoutes } from './AppRoutes'

const requiredRoles = ['superuser', 'admin:econtrat', 'read:econtrat']

const isExpired = (user: any) => !user || !!user.expired

const isDenied = (user: any) => {
  const role = get(user, ['profile', 'role'], false)
  if (!role) return true
  return Array.isArray(role)
    ? !role.some((r) => requiredRoles.includes(r))
    : !requiredRoles.includes(role)
}

export const AuthRoutes: FC = () => {
  const isLoadingUser = useSelector((state: any) => state.oidc.isLoadingUser)
  const user = useSelector((state: any) => state.oidc.user)
  const [isRedirecting, setRedirecting] = useState(false)

  useEffect(() => {
    const isCallback = window.location.pathname.startsWith('/callback')
    const isSilentRenew = window.location.pathname.startsWith('/silent_renew')
    if (!isCallback && !isSilentRenew && !user && !isLoadingUser && !isRedirecting) {
      setRedirecting(true)
      userManager.signinRedirect()
    }
  }, [user, isLoadingUser, isRedirecting])

  if (isExpired(user))
    return (
      <Switch>
        <Route path="/silent_renew">
          <SilentRedirectPage />
        </Route>
        <Route path="/">
          <Callback />
        </Route>
      </Switch>
    )

  if (isDenied(user)) return <AccessDenied />

  return (
    <Switch>
      <Route path="/silent_renew">
        <SilentRedirectPage />
      </Route>

      <Route path="/callback">
        <Callback />
      </Route>

      <Route path="/logout">
        <Logout />
      </Route>

      <Route path="/">
        <AppRoutes />
      </Route>
    </Switch>
  )
}
