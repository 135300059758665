import { useQuery, useMutation, queryCache, AnyQueryKey } from 'react-query'
import { useSelector } from 'react-redux'
import { superFetch } from 'commons'
import { getReady } from 'store/api'
import { getContractId } from 'store/selection'

export const useContractIat = () => {
  const ready = useSelector(getReady)
  const contractId = useSelector(getContractId)
  const { data } = useQuery<Iat[], AnyQueryKey>(
    ready && contractId !== null && ['contractIat', { contractId }],
    () =>
      superFetch({
        url: `contrats/${contractId}/comptefactu`,
      }),
  )
  return data
}

export const useUpdateIat = () => {
  const contractId = useSelector(getContractId)
  return useMutation<Iat[], { contractId: number; iats: Iat[] }>(
    ({ contractId, iats }) =>
      superFetch({
        url: `contrats/${contractId}/comptefactu`,
        method: 'PUT',
        body: iats,
      }),
    {
      useErrorBoundary: false,
      throwOnError: true,
      onSuccess: () => {
        queryCache.refetchQueries(['contractIat', { contractId }])
      },
    },
  )
}
