import React, { Component } from 'react'
import ReactHighcharts from 'react-highcharts/ReactHighstock.src'
import styles from './Chart.module.scss'
import { Spinner } from 'reactstrap'
import moment from 'moment'
import { withTranslation } from 'react-i18next'



const LoadingLayer = () => (
  <div className={styles.loadingLayer}>
    <Spinner className={styles.spinner} />
  </div>
)

class ChartComponent extends Component   {

render() {

    const options = { style: "currency", currency: "USD" };
    const numberFormat = new Intl.NumberFormat(options);
    const series = [];
    const priceDataReel = this.props.dataReel;
    const priceDataPrevu = this.props.dataPrevu;
    var isLoading=true;
    if (priceDataReel.length!=0) {
      series.push({
        name:this.props.t('contract.titleChart.consReel') ,
        type: 'line',
        lineWidth: 0.9,
        data: priceDataReel,
        tooltip: {
        valueDecimals: 2
      },
      });
      isLoading=false;
    }
    if (priceDataPrevu.length!=0) {
      series.push({
        name: this.props.t('contract.titleChart.consPrevu'),
        type: 'line',
        lineWidth: 0.9,
        data: priceDataPrevu,
        tooltip: {
        valueDecimals: 2
      },
      });
      isLoading=false;
    }
    const configPrice = {
    yAxis: [{
      offset: 0,
      labels: {
        formatter: function () {
          return numberFormat.format(this.value) 
        },
        x: -0,
        style: {
          "color": "#000", "position": "absolute"
        },
        align: 'left'
      },
    },    
    ],
    
    plotOptions: {
      series: {
        showInNavigator: true,
        gapSize: 6,
      }
    },
    rangeSelector: {
      selected: 2
    },
    title: {
      text: this.props.t('contract.titleChart.loadCurve'),
    },
    chart: {
      height: 600,
    },

    credits: {
      enabled: false
    },

    legend: {
      enabled: true
    },
    xAxis: {
      type: 'meeting-time',
    },
    rangeSelector: {
      buttons: [{
        type: 'day',
        count: 1,
        text: '1d',
      }, {
        type: 'day',
        count: 7,
        text: '7d'
      }, {
        type: 'month',
        count: 1,
        text: '1m'
      }, {
        type: 'month',
        count: 3,
        text: '3m'
      },
        {
        type: 'all',
        text: 'All'
      }],
      selected: 4
    },
    series: series,
    tooltip: {
      shared: true,
      formatter: function() {
        var s = '<b>' + moment(this.x).format('MMMM Do YYYY, HH:mm:ss') + '</b>';
        this.points.forEach(function(point) {
          s += '<br/><span style="color:' + point.series.color + '">' + point.series.name + ':</span> ' + numberFormat.format(point.y, 0);
        });
        return s;
      }
    }
  };
  return (
    <div>
      {isLoading && <LoadingLayer />}
       <ReactHighcharts config = {configPrice}    
        containerProps={{ style: { height: "10%" , weight :"10%"} }}>
       </ReactHighcharts>
    </div>
  )
}
}
export default withTranslation()(ChartComponent)
