import React from 'react'
import { useSelector } from 'react-redux'
import { FaInfoCircle, FaFileInvoice, FaFileAlt } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

import { useTheme } from 'hooks'
import { getContractId } from 'store/selection'
import { ContractInfos } from 'components/sections/ContractInfos'
import { ContractFacturation } from 'components/sections/ContractFacturation'
import { ContractDocs } from 'components/sections/ContractDocs'
import { Section } from 'components/atoms'

export const ContractPageInfos: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme() as any
  const id = useSelector(getContractId)
  if (id === null) return null

  return (
    <div
      css={{
        display: 'flex',
        '>*': {
          width: '50%',
          ':not(:last-child)': {
            marginRight: theme.spacing,
          },
          '>*:not(:last-child)': {
            flex: 0,
            marginBottom: theme.spacing,
          },
        },
      }}
    >
      <div>
        <Section title={t('contract.infos.title')} Icon={FaInfoCircle}>
          <ContractInfos />
        </Section>
      </div>

      <div>
        <Section title={t('contract.billing.title')} Icon={FaFileInvoice}>
          <ContractFacturation />
        </Section>

        <Section title={t('contract.docs.title')} Icon={FaFileAlt}>
          <ContractDocs />
        </Section>
      </div>
    </div>
  )
}
