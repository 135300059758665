import React from 'react'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import pick from 'lodash/pick'
import { useTranslation } from 'react-i18next'

import { getContractId } from 'store/selection'
import { useContract, useUpdateContract } from 'api'
import { ContractForm } from './ContractForm'
import { schema } from './contractSchema'
import { addNotification } from 'notification'

export const ContractInfos: React.FC = () => {
  const { t } = useTranslation()
  const contractId = useSelector(getContractId)
  const contract = useContract()
  const [mutate, { status }] = useUpdateContract()

  const values = pick(contract, [
    'NomLong',
    'NomCourt',
    'Nom',
    'Complement',
    'Type',
    'Nrj',
    'DebutLivraison',
    'FinLivraison',
    'PaysId',
    'Devise',
    'FournisseurId',
    'DateSignature',
    'DelaiReglement',
    'CompteFacturation',
  ])

  const submit = React.useCallback(
    async (values, actions) => {
      if (contractId === null) return
      try {
        await mutate({
          contractId,
          contract: values,
        })
        actions.setSubmitting(false)
        addNotification({
          type: 'success',
          message: t('contract.infos.updateSuccess', { name: values.NomCourt }),
        })
      } catch (error) {
        addNotification({
          type: 'danger',
          title: t('contract.infos.updateErrorTitle'),
          message: (error as any).toString(),
        })
      }
    },
    [contractId, mutate, t],
  )

  if (!contract) return null

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validationSchema={schema(t)}
      onSubmit={submit}
    >
      {(props) => <ContractForm isLoading={status === 'loading'} {...props} />}
    </Formik>
  )
}
