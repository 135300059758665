import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { superFetch } from 'commons'
import { getReady } from 'store/api'
import mapValues from 'lodash/mapValues'

const ONE_DAY = 86400000

const emptyReferentiel = {
  ListeTypeContrat: [],
  ListeEnergie: [],
  ListeDevise: [],
  ListePays: [],
  ListeDelaiPaiement: [],
  ListeTypeLot: [],
  ListeHorosaisonnier: [],
  ListeFormulePrix: [],
  ListeIat: [],
  ListeTypeDeConso: [],
  ListCHRPas: [],
  ListDesUnites: [],
  ListTypeFormatCsv: [],
  ListValidationStatus: []
}

export const useReferentiel = () => {
  const ready = useSelector(getReady)

  const { data } = useQuery<Referentiel, string>(
    ready && 'referentiel',
    () => superFetch({ url: 'referentiel/contrat' }),
    { staleTime: ONE_DAY },
  )

  return data
    ? mapValues(data, (list) => list.map((item) => ({ value: item.Id, label: item.Nom })))
    : emptyReferentiel
}
